
// notify
export function notify(type, title, message)
{
	console.log(type + ' | ' + title + ': ' + message);
}

// add notification panel
export function addNotificationPanel(target, notification)
{
	var _result =
		'<div class="alert alert-' + notification.Type + '" role="alert">' +
		(notification.Title ? '<h4 class="alert-heading">' + notification.Title + '</h4>' : '') +
		notification.Message +
		'</div>'

	target.empty();
	target.append(_result);
}

// async submit
export function asyncSubmit(form, successCallback, errorCallback)
{
	// current language by html tag
	var _currentLanguage = $('html').attr('lang');

	// append page language to form data
	var _formData = form.serialize() + '&lang=' + _currentLanguage;
	var _formInput = $(form).find('[type="submit"]');

	// input
	_formInput.attr('data-ajax', 'loading');
	_formInput.prepend('<i class="fas fa-spinner fa-spin wp-input-loader"></i>');
	_formInput.prop("disabled", true)

	$.ajax({
		type: "POST",
		url: form.attr('action'),
		data: _formData,
		success: function (result)
		{
			_formInput.removeAttr('data-ajax');
			_formInput.find('.wp-input-loader').remove();
			_formInput.prop("disabled", false);

			// success callback
			successCallback(result);
		},
		error: function (jqXHR, textStatus, errorThrown)
		{
			console.log(jqXHR);

			_formInput.removeAttr('data-ajax');
			_formInput.find('.wp-input-loader').remove();
			_formInput.prop("disabled", false);

			// error callback
			errorCallback(jqXHR, textStatus, errorThrown);
		}
	});
}

// clear service worker cache
export function clearCache(callback)
{
	if ('serviceWorker' in navigator)
	{
		caches.keys().then(function (names)
		{
			for (i = 0; i < names.length; i++)
			{
				caches.delete(names[i]);
			}

			callback();
		});
	}
}

// url base 64 to array
export function urlBase64ToUint8Array(base64String)
{
	var padding = '='.repeat((4 - base64String.length % 4) % 4);
	var base64 = (base64String + padding)
		.replace(/\-/g, '+')
		.replace(/_/g, '/');

	var rawData = window.atob(base64);
	var outputArray = new Uint8Array(rawData.length);

	for (var i = 0; i < rawData.length; ++i)
	{
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}