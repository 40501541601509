export function Init()
{
	// improve scrolling performance on mobile
	document.addEventListener('touchstart', function () { }, { passive: true });

	// intersection observer
	InitIntersectionObserver();

	// logout
	InitLogout();

	// cookie bar
	// InitCookieBar();
}

// logout
function InitLogout()
{
	$('[data-action="logout"]').click(function (e)
	{
		e.preventDefault();
		var _form = $(this).closest('form');

		_utils.asyncSubmit(_form,

			// success
			function (data)
			{
				// clear sw cache
				_utils.clearCache(function ()
				{
					// redirect
					var _redirectUrl = data.Properties.RedirectUrl;
					$(window.location).attr('href', _redirectUrl);
				});
			}
		);
	});
}

// intersection observer
function InitIntersectionObserver()
{
	const _targets = document.querySelectorAll('[data-lazy]');

	if (!('IntersectionObserver' in window))
	{
		for (var i = 0; i < _targets.length; i++)
		{
			ShowSource(_targets[i]);
		}
	}
	else
	{
		const _lazyLoad = function (target)
		{
			const io = new IntersectionObserver(function (entries, observer)
			{
				for (var i = 0; i < entries.length; i++)
				{
					if (entries[i].isIntersecting)
					{
						const _target = entries[i].target;

						ShowSource(_target);

						observer.disconnect();
					}
				}
			});

			io.observe(target);
		};

		for (var j = 0; j < _targets.length; j++)
		{
			_lazyLoad(_targets[j]);
		}
	}
}

// cookie bar
function InitCookieBar()
{
	var _infoLink = '/privacy-cookie-policy';
	switch ($('html').attr('lang'))
	{
		case 'en':
			_infoLink = '/en/privacy-cookie-policy';
			break;
	}

	$.cookieBar({
		style: 'bottom',
		infoTarget: '_self',
		infoLink: _infoLink
	});
}

/* helpers */
function ShowSource(Target)
{
	const _src = Target.getAttribute('data-lazy');

	switch (Target.tagName)
	{
		case 'IMG':
			{
				Target.setAttribute('src', _src);
			}
			break;
		case 'SOURCE':
			{
				Target.setAttribute('srcset', _src);
			}
			break;
	}

	Target.classList.add('show');
}