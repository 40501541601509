import * as _globalProvider from './providers/wappo.providers.global';
import * as _pushNotificationsProvider from './providers/wappo.providers.pushnotifications';
import * as _cookieBar from './plugins/wappo.plugins.cookiebar';
import * as _bootstrap from 'bootstrap';
import * as _utils from './utils/wappo.utils';

$(document).ready(function ()
{
    // global initializations
    _globalProvider.Init();

    // push notification
    _pushNotificationsProvider.Init();
});