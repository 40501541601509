
import * as _serviceWorkerProvider from './../providers/wappo.providers.serviceWorker';

export function Init()
{
	// subscribe to push notifications action
	$('[data-action="ws-notifications-subscribe"]').click(function (e)
	{
		_serviceWorkerProvider.askPermission().then(
			_serviceWorkerProvider.subscribe(function (result)
			{
			})
		);
	});

	// unsubscribe to push notifications action
	$('[data-action="ws-notifications-unsubscribe"]').click(function (e)
	{
		_serviceWorkerProvider.unsubscribe(function (result)
		{

		});
	});

	// send test notification
	$('[data-action="ws-notifications-test"]').click(function (e)
	{
		_serviceWorkerProvider.sendTest();
	});
}