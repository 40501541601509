import { urlBase64ToUint8Array } from './../utils/wappo.utils';
import { _vapidPublicKey } from './../utils/wappo.const'

var _newWorker;
var _refreshing;
var _version = "1.0";

// check for navigator browser support
if ('serviceWorker' in navigator)
{
	// update notification click
	if (document.getElementById('SwUpdateNotification'))
	{
		document.getElementById('SwUpdateNotification').addEventListener('click', function ()
		{
			navigator.serviceWorker.ready.then(function (registration)
			{
				_newWorker.postMessage({ action: 'skipWaiting' });
			});
		});
	}

	// the event listener that is fired when the service worker updates
	navigator.serviceWorker.addEventListener('controllerchange', function ()
	{
		if (_refreshing) return;
		window.location.reload();
		_refreshing = true;
	});

	// sw registration
	navigator.serviceWorker.register('/serviceworker.js').then(function (registration)
	{
		// check for uppdates
		registration.onupdatefound = function ()
		{
			_newWorker = registration.installing;

			_newWorker.onstatechange = function ()
			{
				switch (_newWorker.state)
				{
					case 'installed':
						{
							// new update available
							if (navigator.serviceWorker.controller)
							{
								document.getElementById('SwUpdateNotification').className = 'wp-update-box show';
							}
						}
						break;
				}
			};
		};

		// get web push subscription status
		getSubscriptionStatus(function (result)
		{
			updateNotificationsStatus(result);
		});
	});
}
else
{
	console.log('Service worker is not supported.');
}

// ask for push notifications
export function askPermission()
{
	return new Promise(function (resolve, reject)
	{
		const permissionResult = Notification.requestPermission(function (result)
		{
			resolve(result);
		});

		if (permissionResult)
		{
			permissionResult.then(resolve, reject);
		}
	})
		.then(function (permissionResult)
		{
			if (permissionResult !== 'granted')
			{
				alert('Permessi negati. Controlla le impostazioni del tuo browser.');
			}
		});
}

// subscribe user to push notification
export function subscribe(callback)
{
	return navigator.serviceWorker.ready.then(function (registration)
	{
		const subscribeOptions =
		{
			userVisibleOnly: true,
			applicationServerKey: urlBase64ToUint8Array(_vapidPublicKey)
		};

		return registration.pushManager.subscribe(subscribeOptions);
	})
		.then(function (pushSubscription)
		{
			var _subJSObject = JSON.parse(JSON.stringify(pushSubscription));
			var _endpoint = _subJSObject.endpoint;
			var _p256dh = _subJSObject.keys.p256dh;
			var _auth = _subJSObject.keys.auth;

			// registering subscription
			$.post('/umbraco/api/notifications/subscribe', { EndPoint: _endpoint, P256DH: _p256dh, Auth: _auth }, function (data)
			{
				updateNotificationsStatus(true);
				callback(true);
			});
		});
}

// unsubscribe user from push notification
export function unsubscribe(callback)
{
	navigator.serviceWorker.ready.then(function (reg)
	{
		reg.pushManager.getSubscription().then(function (subscription)
		{
			if (subscription)
			{
				var _subJSObject = JSON.parse(JSON.stringify(subscription));
				var _endpoint = _subJSObject.endpoint;
				var _p256dh = _subJSObject.keys.p256dh;
				var _auth = _subJSObject.keys.auth;

				subscription.unsubscribe().then(function (successful)
				{
					// unsubscribing from db
					$.post('/umbraco/api/notifications/unsubscribe', { EndPoint: _endpoint, P256DH: _p256dh, Auth: _auth }, function (data)
					{
						updateNotificationsStatus(false);
						callback(true);
					});

				}).catch(function (e)
				{
					callback(false);
				});
			}
			else
			{
				console.log('user not subscribed');
			}
		})
	});
}

// test
export function sendTest()
{
	$.post('/umbraco/api/notifications/sendtest', {}, function (data)
	{

	});
}

// unsubscribe user from push notification
export function getSubscriptionStatus(callback)
{
	navigator.serviceWorker.ready.then(function (registration)
	{
		registration.pushManager.getSubscription().then(function (pushSubscription)
		{
			if (pushSubscription)
			{
				var _subJSObject = JSON.parse(JSON.stringify(pushSubscription));
				var _endpoint = _subJSObject.endpoint;

				// check: subscription server side
				$.get('/umbraco/api/notifications/getSubscription?EndPoint=' + _endpoint, function (data)
				{
					callback(true);
				});
			}
			else
			{
				callback(false);
			}
		})
	});
}

/*--------------------------------------------
 internals 
--------------------------------------------*/

function updateNotificationsStatus(status)
{
	$('[data-value="ws-subscription-status"]').html(status ? 'Notifiche attive' : 'Notifiche non attive');
}