
$(document).ready(function ()
{
	// navigation
	$('.nav-menu a').click(function (e)
	{
		var _target = $($(this).attr('href'));
		$('html,body').animate({ scrollTop: _target.offset().top - 100 }, 'slow');
	});

	//map
	initMap();
});

function initMap()
{
	const myLatLng = { lat: 50.826550, lng: 4.362300 };

	let map = new google.maps.Map(document.getElementById("map"), {
		center: myLatLng,
		zoom: 18,
	});

	new google.maps.Marker({
		position: myLatLng,
		map,
		title: "Inter Club Europa Bruxelles",
	});
}
